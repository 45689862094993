import { FC, createContext, useContext, useState } from 'react';
import { Box, Button } from '@mui/material';
import { H6, Paragraph } from '../components/UI/StyledComponents/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTaskIndicatorContext } from './TaskIndicatorContext';
import CloseIcon from '@mui/icons-material/Close';

export interface IConsentBoxContext {
  openConsentBox: (action: () => void) => void;
}

export const ConsentBoxContext = createContext<IConsentBoxContext | undefined>(
  undefined
);

export const useConsentContext = () => {
  const context = useContext(ConsentBoxContext);

  if (!context) {
    throw new Error(
      'useConsentContext must be used within a ConsentContextProvider'
    );
  }

  return context;
};

export const ConsentProvider: FC<any> = ({ children }) => {
  const ConsentContextProvider = ConsentBoxContext.Provider as any;
  const taskIndicator = useTaskIndicatorContext();

  const openConsentBox = (action: () => void) => {
    taskIndicator.setOpenTaskIndicator(true);
    taskIndicator.setMessage(
      <WaringAlert
        action={action}
        closeAlert={taskIndicator.setOpenTaskIndicator}
      />
    );
  };

  const WaringAlert: FC<{ closeAlert: Function; action: () => void }> = ({
    closeAlert,
    action
  }) => {
    const [isClicked, setIsClicked] = useState(false);

    const dismissAlert = () => {
      closeAlert(false);
    };

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          color: '#1d2438',
          gap: 3
        }}
      >
        <Box sx={styles.warningIcon}>
          <WarningAmberIcon color="error" fontSize="large" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <H6>Action Required</H6>
          <Paragraph sx={{ marginBottom: 2, maxWidth: 350 }}>
            Are you sure you want to proceed with this action. If yes then click{' '}
            <b>Proceed</b> else click <b>Cancel</b>
          </Paragraph>
          <Box sx={{ textAlign: 'right' }}>
            <Button onClick={dismissAlert}>Cancel</Button>
            <Button
              onClick={() => {
                if (isClicked) return;
                if (action) action();
                setIsClicked(true);
                dismissAlert();
              }}
              color="primary"
              variant="contained"
            >
              Proceed
            </Button>
          </Box>
          <Button
            onClick={dismissAlert}
            color="inherit"
            variant={'contained'}
            sx={{
              ...styles.closeButton,
              position: 'absolute',
              top: -6,
              right: -6
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <ConsentContextProvider
      value={{
        openConsentBox
      }}
    >
      {children}
    </ConsentContextProvider>
  );
};

const styles = {
  warningIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    width: '100px',
    borderRadius: '50px',
    backgroundColor: '#fee6ea'
  },
  closeButton: {
    borderRadius: '50%',
    padding: '0 !important',
    width: '2rem',
    height: '2rem',
    minWidth: '2rem',
    backgroundColor: 'white',

    '&:hover': {
      backgroundColor: 'inherit.main',
      color: 'black'
    }
  }
};
