import Snackbar from "@mui/material/Snackbar";
import { FC, createContext, useContext, useState } from "react";

export type Vertical = "top" | "bottom";
export type Horizontal = "left" | "center" | "right";

export interface ITaskIndicatorPosition {
  vertical: Vertical;
  horizontal: Horizontal;
}

export interface ITaskIndicatorContext {
  taskId?: string;
  openTaskIndicator: boolean;
  message: React.ReactNode;
  action: React.ReactNode;
  setPosition: (position: ITaskIndicatorPosition) => void;
  setTaskId: (taskId: string) => void;
  setOpenTaskIndicator: (open: boolean) => void;
  setMessage: (message: React.ReactNode | string) => void;
  setAction: (action: React.ReactNode) => void;
}

export const TaskIndicatorContext = createContext<
  ITaskIndicatorContext | undefined
>(undefined);

export const useTaskIndicatorContext = () => {
  const context = useContext(TaskIndicatorContext);

  if (!context) {
    throw new Error(
      "useTaskIndicatorContext must be used within a TaskIndicatorContextProvider",
    );
  }

  return context;
};

export const TaskIndicatorProvider: FC<any> = ({ children }) => {
  const [taskId, setTaskId] = useState<string | undefined>(undefined);
  const [openTaskIndicator, setOpenTaskIndicator] = useState<boolean>(false);
  const [position, setPosition] = useState<ITaskIndicatorPosition>({
    vertical: "bottom",
    horizontal: "right",
  });
  const [message, setMessage] = useState<React.ReactNode>(undefined);
  const [action, setAction] = useState<React.ReactNode>(undefined);

  const TaskIndicatorContextProvider = TaskIndicatorContext.Provider as any;
  return (
    <TaskIndicatorContextProvider
      value={{
        taskId,
        setTaskId,
        openTaskIndicator,
        setPosition,
        setOpenTaskIndicator,
        setMessage,
        setAction,
      }}
    >
      <Snackbar
        key={taskId}
        open={openTaskIndicator}
        autoHideDuration={null}
        message={message}
        action={action}
        anchorOrigin={{
          vertical: position.vertical,
          horizontal: position.horizontal,
        }}
        sx={{ ...styles.snackbar }}
      />
      {children}
    </TaskIndicatorContextProvider>
  );
};

const styles = {
  snackbar: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "white !important",
    },
    "& .MuiSnackbarContent-message": {
      width: "100% !important",
    },
  },
};
