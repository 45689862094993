import React from 'react';

import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
import { RouterLazyLoader } from './router-loader';

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace={true} />
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Login />
          </GuestGuard>
        )
      },
      {
        path: 'account-setup',
        element: (
          <GuestGuard>
            <RouterLazyLoader.AccountSetup />
          </GuestGuard>
        )
      },
      {
        path: '*',
        element: (
          <GuestGuard>
            <RouterLazyLoader.Status404 />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <SidebarLayout />,
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/app/dashboard" replace={true} />
      },
      {
        path: 'dashboard',
        element: <RouterLazyLoader.Dashboard />
      },
      {
        path: 'applications',
        element: <RouterLazyLoader.Applications />
      },
      {
        path: 'applications/details/:id',
        element: <RouterLazyLoader.ApplicationDetails />
      },
      {
        path: 'users',
        element: <RouterLazyLoader.Users />
      },
      {
        path: 'admins',
        element: <RouterLazyLoader.Admins />
      },
      {
        path: 'faq',
        element: <RouterLazyLoader.FAQs />
      },
      {
        path: 'messages',
        element: <RouterLazyLoader.Messages />
      },
      {
        path: 'settings',
        element: <RouterLazyLoader.Settings />
      },
    ]
  },
];

export default routes;
