import jwtDecode from 'jwt-decode';
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState
} from 'react';
import axiosInstance from '../api';
import axios from 'axios';
import { API_BASE_URL } from '../api/constants';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { UserRoles } from 'src/constants/user-roles';

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};

enum Types {
  Init = 'INIT',
  Login = 'LOGIN',
  Logout = 'LOGOUT'
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Login]: { user: AuthUser };
};

type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INIT': {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext: any = createContext({
  ...initialState,
  method: 'JWT',
  login: (email: string, password: string) => Promise.resolve(),
  logout: () => {}
});

// props type
type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [getUserDetails, setGetUserDetails] = useState(false);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}auth/sign-in`,
        {
          email,
          password
        },
        {
          headers: {
            'User-Role': UserRoles.Admin
          }
        }
      );

      const { accessToken, refreshToken }: any = response.data;
      setSession(accessToken);

      dispatch({
        type: Types.Login,
        payload: { user: {} }
      });
      setGetUserDetails(true);
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response.data));
    }
  };

  const logout = () => {
    setSession(null);
    setGetUserDetails(false);
    dispatch({ type: Types.Logout });
  };

  const getUser = async (): Promise<any> => {
    try {
      const response = await axiosInstance.get(`user/me`);
      return response.data;
    } catch (error: any) {
      return {};
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          dispatch({
            type: Types.Init,
            payload: {
              user: await getUser(),
              isAuthenticated: true
            }
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false
            }
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false
          }
        });
      }
    })();
  }, [getUserDetails]);

  if (!state.isInitialized) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
