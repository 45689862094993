import { MenuIcons } from './MenuIcons';

export interface IMenuItem {
  link: string;
  icon: any;
  title: string;
  isVisible?: (user: any) => boolean;
}

const DashboardMenuItems: IMenuItem[] = [
  {
    link: '/app/dashboard',
    title: 'Dashboard',
    icon: MenuIcons.Dashboard
  },
  {
    link: '/app/messages',
    title: 'Inbox',
    icon: MenuIcons.Message
  }
];

const RecruitmentMenuItems: IMenuItem[] = [
  {
    link: '/app/applications',
    title: 'Applications',
    icon: MenuIcons.Form
  },
  {
    link: '/app/users',
    title: 'Candidates',
    icon: MenuIcons.Users
  }
];

const OrganisationMenuItems: IMenuItem[] = [
  {
    link: '/app/admins',
    title: 'Admins',
    icon: MenuIcons.Admins
  },
  {
    link: '/app/faq',
    title: 'FAQs',
    icon: MenuIcons.FAQ
  },

  {
    link: '/app/settings',
    title: 'Settings',
    icon: MenuIcons.Settings
  }
];

export { DashboardMenuItems, RecruitmentMenuItems, OrganisationMenuItems };
