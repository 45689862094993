import { FC, Suspense, lazy } from "react";
import SuspenseLoader from "./components/SuspenseLoader";

const Loader = (Component: FC<any>) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Authentication
const Login = Loader(lazy(() => import('src/pages/Authentication/Login/Login')));

// Account Setup
const AccountSetup = Loader(lazy(() => import('src/pages/AccountSetup')));

// Dashboards
const Dashboard = Loader(lazy(() => import('src/pages/Dashboard')));

// Applications
const Applications = Loader(lazy(() => import('src/pages/Recruitment/Applications')));
const ApplicationDetails = Loader(lazy(() => import('src/pages/Recruitment/Applications/Details')));

// Users
const Users = Loader(lazy(() => import('src/pages/Recruitment/Applicants')));

// Admins
const Admins = Loader(lazy(() => import('src/pages/Organisation/Admins')));

// FAQs
const FAQs = Loader(lazy(() => import('src/pages/Organisation/FAQ')));

// Messages
const Messages = Loader(lazy(() => import('src/pages/Organisation/Messages')));

// Settings
const Settings = Loader(lazy(() => import('src/pages/Settings')));

// Error Pages
const Status404 = Loader(lazy(() => import('src/pages/Error/Status404')));

export const RouterLazyLoader = {
    Login,
    Dashboard,
    Status404,
    Settings,
    Messages,
    FAQs,
    Users,
    Applications,
    Admins,
    ApplicationDetails,
    AccountSetup
}
