import { configureStore } from '@reduxjs/toolkit';
import ApplicantReducer from '../pages/Recruitment/Applicants/slices/PaginationSlice';
import ApplicationFormReducer from '../pages/Recruitment/Applications/slices/PaginationSlice';

export const store = configureStore({
  reducer: {
    ApplicantPagination: ApplicantReducer,
    ApplicationFormPagination: ApplicationFormReducer
  }
});
